import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AwareHttpRequest, AwareHttpService, IAwareCollection } from '@appbolaget/aware-http';
import { Client } from '@appbolaget/aware-model';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable } from 'rxjs';
import { debounceTime, filter, finalize, map, skip, startWith, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { ContentMapService } from '../content-map.service';
import { ContentMapItem } from '../ContentMapItem';
import { PrefixUrlPipe } from '@helpers/pipes';
import { AwareAuth, AwareAuthState } from '@appbolaget/aware-auth';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ScrollableContentComponent, ScrollableContentDirective } from '@helpers/directives';
import { ContentMapActivitiesComponent } from '../activities/activities.component';
import { ContentViewComponent } from '@components/editor/content-view.component';

@Component({
    selector: 'content-map-main-dialog',
    templateUrl: 'content-map-dialog.component.html',
    styleUrls: ['./content-map-dialog.component.scss'],
    providers: [PrefixUrlPipe],
    standalone: true,
    imports: [
        MatToolbarModule,
        MatDialogModule,
        DragDropModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        AsyncPipe,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        ScrollableContentDirective,
        ScrollableContentComponent,
        ContentMapActivitiesComponent,
        ContentViewComponent,
    ],
})
export class ContentMapDialogComponent implements OnInit, OnDestroy {
    ALIVE: boolean;
    IS_LOADING: boolean;

    @AwareAuth(AwareAuthState.client) me$: Observable<Client>;

    categories$: Observable<IAwareCollection<ContentMapItem>>;
    breadcrumbs$ = this.service.breadcrumbs$;
    lastBreadcrumb$: Observable<ContentMapItem> = merge(
        this.breadcrumbs$.pipe(map((breadcrumbs) => breadcrumbs[breadcrumbs.length - 1])),
        this.service.toggledFavorite$,
    );
    isFavoriteView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    searchControl = new FormControl();
    itemBackgroundColor: string;

    prefixUrlPipe = inject(PrefixUrlPipe);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<any>,
        private api: AwareHttpService,
        private service: ContentMapService,
    ) {}

    ngOnInit() {
        this.ALIVE = true;

        this._getPosts();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    dismiss() {
        this.dialogRef.close();
    }

    goHome() {
        this.searchControl.setValue('');
        // this.service.clearBreadcrumbs();
    }

    async onBackClick() {
        const currentItem = await firstValueFrom(this.lastBreadcrumb$);
        if (currentItem.parent && currentItem.parent.type === 'content-map-item') {
            this.service.setBreadcrumbsFromParent(currentItem.parent);
        } else {
            this.service.popBreadcrumb();
        }
    }

    onItemClick(post: ContentMapItem) {
        const itemType = post.attr('type');

        switch (itemType) {
            case 'url':
                window.open(this.prefixUrlPipe.transform(post.attr('url', 'text') ?? post.attr('url')), '_blank');
                break;

            default:
                this.isFavoriteView$.next(false);
                this.service.addBreadcrumb(post);
                break;
        }
    }

    async toggleFavorite(post: ContentMapItem) {
        const me = await firstValueFrom(this.me$);
        this.service.toggleFavorite(post, me).subscribe();
    }

    toggleFavoritesView() {
        this.service.clearBreadcrumbs();
        this.isFavoriteView$.next(!this.isFavoriteView$.value);
    }

    private _getPosts() {
        const query$: Observable<string> = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            tap(() => this.service.clearBreadcrumbs()),
            startWith(''),
        );

        const rootContentMap$ = this.service.rootContentMap$.pipe(filter((rootContentMap) => !!rootContentMap));

        const breadcrumbs$ = this.service.breadcrumbs$;

        const values$ = combineLatest([rootContentMap$, query$, breadcrumbs$, this.isFavoriteView$]);

        this.categories$ = values$.pipe(
            takeWhile(() => this.ALIVE),
            debounceTime(50), // To avoid multiple requests when multiple input streams emit at the same time
            tap(() => (this.IS_LOADING = true)),
            switchMap(([rootContentMap, query, breadcrumbs, isFavoriteView]) => {
                const isRootRequest = !breadcrumbs.length;
                const isSearching = query.length > 0 && isRootRequest;

                let request: AwareHttpRequest = this.api
                    .get<IAwareCollection<ContentMapItem>>('categories/search')
                    .parameter('query', isSearching ? query : null)
                    .sort('position', 'asc')
                    .parameter('parent_id', isSearching ? null : isRootRequest ? rootContentMap.id : breadcrumbs[breadcrumbs.length - 1].id)
                    .parameter('client-pivots', true)
                    .stream('up')
                    .type('content-map-item')
                    .header('hidden', '0')
                    .with('attributes')
                    .header('hidden', '0')
                    .limit(100)
                    .toCollection(ContentMapItem);

                if (isSearching) {
                    request = request.with('attributes,parent^*.attributes').parameter('parent', rootContentMap.uuid);
                }

                if (isFavoriteView) {
                    return this.service.getFavorites().pipe(finalize(() => (this.IS_LOADING = false)));
                }

                return request.execute().pipe(
                    takeUntil(values$.pipe(skip(1))),
                    map(isSearching ? this._setItemColors : (posts) => posts),
                    tap(() => {
                        if (!isSearching) {
                            // We are not searching, so set background color for the last breadcrumb
                            this.itemBackgroundColor = breadcrumbs.reduce((acc: string, item: ContentMapItem) => {
                                if (item.attr('color')) {
                                    acc = item.attr('color');
                                } else if (item.localProps?.color) {
                                    acc = item.localProps.color;
                                }

                                return acc;
                            }, null);
                        }
                    }),
                    finalize(() => (this.IS_LOADING = false)),
                );
            }),
        );
    }

    private _setItemColors(posts: IAwareCollection<ContentMapItem>): IAwareCollection<ContentMapItem> {
        const getColor = (item: ContentMapItem) => {
            let color = null;

            if (item.attr('color')) {
                color = item.attr('color');
            } else if (item.parent) {
                color = getColor(item.parent);
            }

            return color;
        };

        posts.data = posts.data.map((post) => {
            post.localProps = {
                color: getColor(post),
            };

            return post;
        });

        return posts;
    }
}
